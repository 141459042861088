import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VTimelineItem,{attrs:{"color":"#e0001a","small":""}},[_c(VCard,{attrs:{"elevation":"5"}},[(_vm.note.isSolution)?_c(VCardSubtitle,{staticClass:"note-solution-title mb-3"},[_c(VRow,[_c(VCol,{staticClass:"text-h6"},[_vm._v("Oplossing")]),_c(VCol,{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("dateFilter")(_vm.note.createdOn)))])],1)],1):_vm._e(),(_vm.note.contactName && !_vm.note.isSolution)?_c(VCardSubtitle,[_c(VRow,[_c(VCol,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(VIcon,[_vm._v(" "+_vm._s(_vm.note.fromSupplier ? "mdi-office-building" : "mdi-account"))]),_vm._v(" "+_vm._s(_vm.note.contactName)+" ")],1)]}}],null,false,1385122032)},[_c('span',[_vm._v(_vm._s(_vm.note.contactEmail))])])],1),_c(VCol,{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("dateFilter")(_vm.note.createdOn)))])],1)],1):_vm._e(),_c(VCardText,{staticClass:"black--text ticket-text"},[_vm._v(" "+_vm._s(_vm.note.body)+" ")]),_c(VDivider)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
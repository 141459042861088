import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,[_c(VCol,[_c(VCard,{staticClass:"mx-auto",attrs:{"tile":"","max-width":"600","loading":_vm.isLoading}},[_c(VCardText,{staticClass:"text-center black--text"},[_c(VImg,{staticClass:"mx-auto py-5",attrs:{"alt":"Unica Logo","contain":"","src":"/img/Unica-ICT-Solutions_logo.svg","max-width":"150px"}}),_vm._v(" Welkom op het eindgebruikersportaal van"),_c('br'),_vm._v(" Unica ICT Solutions. ")],1)],1)],1)],1),(!_vm.isAuthenticated)?_c(VRow,[_c(VCol,[_c(VCard,{staticClass:"mx-auto",attrs:{"elevation":"5","width":"400"},on:{"click":_vm.signIn}},[_c(VCardTitle,{staticClass:"justify-center"},[_vm._v(" Klik hier om in te loggen"),_c('img',{staticClass:"ml-2",attrs:{"src":"/img/mssymbol.svg","alt":""}})])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from "vue";
import Vuex from "vuex";
import { IAppState } from "./modules/app";

Vue.use(Vuex);

export interface IRootState {
  app: IAppState;
}

const store = new Vuex.Store<IRootState>({});
export default store;

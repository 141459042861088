import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c('ErrorCard'),_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"800","elevation":"5"}},[_c(VImg,{attrs:{"width":"100%","src":"/img/profile-header.png"}}),_c(VCardTitle,{staticClass:"text-h4"},[_vm._v(" Profiel ")]),_c(VListItem,{attrs:{"color":"rgba(0, 0, 0, .4)"}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"title"},[_vm._v(_vm._s(_vm.userProfile.name))]),_c(VDivider),_c(VListItem,[_vm._v("Bedrijfsnaam: "+_vm._s(_vm.userProfile.companyName)+" ("+_vm._s(_vm.userProfile.ofbKltId)+")")]),_c(VListItem,[_vm._v("E-mail: "+_vm._s(_vm.userProfile.emailAddress))]),_c(VListItem,[_vm._v("Telefoonnummer: "+_vm._s(_vm.userProfile.phone))]),_c(VListItem,[_vm._v("Dagen beschikbaar: "+_vm._s(_vm.daysAvailable()))])],1)],1),_c(VDivider),_c(VCardTitle,{staticClass:"text-h4"},[_vm._v(" Account info ")]),_c(VListItem,{attrs:{"color":"rgba(0, 0, 0, .4)"}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"title"},[_vm._v(_vm._s(_vm.profile.displayName))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.profile.jobTitle))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.profile.department))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.profile.companyName))]),_c(VDivider),_c(VListItem,[_vm._v("E-mail: "+_vm._s(_vm.profile.mail))]),_c(VListItem,[_vm._v("Telefoonnummer: "+_vm._s(_vm.profile.mobilePhone))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"mx-auto"},[_c('ErrorCard'),_c(VRow,[_c(VCol,{staticClass:"pb-0"},[_c(VCard,{attrs:{"tile":""}},[_c(VCardActions,{staticClass:"py-0"},[_c(VCol,{staticClass:"d-flex justify-center"},[_c(VBtn,{staticClass:"no-cursor",attrs:{"elevation":"2","color":"#cbdbe4"}},[_vm._v("Filter op status:")]),_c(VBtn,{staticClass:"mx-2",attrs:{"elevation":"2","width":"110","color":"primary","outlined":_vm.btnStateOutlined},on:{"click":function($event){return _vm.setListState()}}},[_vm._v(_vm._s(_vm.btnStateText))])],1)],1)],1)],1),_c('TicketList',{attrs:{"tickets":_vm.tickets,"loading":_vm.loading}})],1),_c(VRow,[_c(VCol,[_c(VCard,{staticClass:"d-flex flex-row justify-center",attrs:{"color":"transparent","elevation":"0"}},[(_vm.props.listState)?_c('CountWidget',{attrs:{"label":"Open","value":_vm.props.ticketCountOpen}}):_vm._e(),(_vm.props.listState)?_c('CountWidget',{attrs:{"label":"In behandeling","value":_vm.props.ticketCountInProgress}}):_vm._e(),(_vm.props.listState)?_c('CountWidget',{attrs:{"label":"Wachten op klant","value":_vm.props.ticketCountWaitingForCustomer}}):_vm._e(),(!_vm.props.listState)?_c('CountWidget',{attrs:{"label":"Opgelost","value":_vm.props.ticketCountSolved}}):_vm._e(),(!_vm.props.listState)?_c('CountWidget',{attrs:{"label":"Gesloten","value":_vm.props.ticketCountClosed}}):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
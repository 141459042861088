import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.error.name)?_c(VRow,[_c(VCol,{attrs:{"md":"12","cols":"6"}},[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"800","elevation":"5"},on:{"click":function($event){return _vm.closeError()}}},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.error.name)+" ")]),_c(VCardText,[_c(VAlert,{attrs:{"type":"error"}},[_vm._v(" Foutmelding: "+_vm._s(_vm.error.message)+" ")])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }

import AppModule from "@/store/modules/app";
import { Component, Vue } from "vue-property-decorator";
import { VForm } from "@/services";

@Component
export default class RedirectAt extends Vue {
  formIsValid = false;
  isLoading = false;
  isError = false;

  emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  emailRules = [
    (v: string): boolean | string => !!v || this.emailRegex.test(v) || "E-mailadres is ongeldig",
  ];

  emailAddress = (this.$route.query.email as string) || "";
  ticketId = (this.$route.query.ticketId as string) || "";

  validateForm(): boolean {
    this.formIsValid = (this.$refs.form as VForm).validate();
    return this.formIsValid;
  }

  redirectAuthenticated(): void {
    this.isLoading = true;
    this.$dataService.GetAutotaskRedirectUrl(this.ticketId).then((url) => {
      this.handleUrl(url, true);
    });
  }

  handleSubmit(): void {
    this.formIsValid = (this.$refs.form as VForm).validate();

    if (!this.formIsValid) {
      return;
    }

    this.isLoading = true;
    this.isError = false;

    this.$dataService
      .GetAnonymousAutotaskRedirectUrl(this.emailAddress, this.ticketId)
      .then((url) => {
        this.handleUrl(url, false);
      })
      .catch(() => {
        this.isError = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  handleUrl(url: string, goBack: boolean): void {
    let target = "_blank";

    window.open(url, target);

    if (target == "_blank" && goBack) {
      this.$router.go(-1);
    }
  }

  mounted(): void {
    if (AppModule.msal.isAuthenticated) {
      this.redirectAuthenticated();
    }
  }
}

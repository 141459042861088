import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VAppBar,{attrs:{"elevation":"5","app":"","color":"white"}},[_c(VRow,[(_vm.isProfileLoaded)?_c(VCol,[_c(VBtn,{staticClass:"mr-2",attrs:{"to":"/home","icon":"","x-large":"","color":"primary"}},[_c(VIcon,[_vm._v("mdi-home")])],1),_c(VBtn,{attrs:{"to":"/ticket/new","icon":"","x-large":"","color":"primary"}},[_c(VIcon,[_vm._v("mdi-plus")])],1)],1):_vm._e(),_c(VCol,{staticClass:"d-flex justify-end"},[(_vm.isAuthenticated)?_c(VBtn,{staticClass:"mr-2",attrs:{"to":"/profile","icon":"","x-large":"","color":"primary"}},[_c(VIcon,[_vm._v("mdi-account")])],1):_vm._e(),_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","x-large":"","color":"primary"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-menu")])],1)]}}])},[_c(VList,[(_vm.isAuthenticated && _vm.isProfileLoaded)?_c(VListItem,{on:{"click":_vm.gotoRedirectAt}},[_c(VListItemTitle,[_vm._v("Nieuw klantenportaal")])],1):_vm._e(),(_vm.isProfileLoaded)?_c(VListItem,{attrs:{"to":"/ticket/new"}},[_c(VListItemTitle,[_vm._v("Nieuwe ticket")])],1):_vm._e(),_c(VListItem,{attrs:{"to":"/help"}},[_c(VListItemTitle,[_vm._v("Help")])],1),(!_vm.isAuthenticated)?_c(VListItem,{attrs:{"to":"/"}},[_c(VListItemTitle,[_vm._v("Aanmelden")])],1):_vm._e(),(_vm.isAuthenticated)?_c(VListItem,{on:{"click":function($event){return _vm.signOut()}}},[_c(VListItemTitle,[_vm._v("Afmelden")])],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { VuexModule, Module, Mutation, Action, getModule } from "vuex-module-decorators";
import store from "@/store";
import { AccessToken, MsalData } from "@/auth";
import { ApplicationError, Ticket, TicketNote, UserProfile } from "@/services";

export interface IAppState {
  isLoading: boolean;
  companyName: string;

  msal: MsalData;
  accessTokens: AccessToken[];

  userProfile: UserProfile;
  ticketList: Ticket[];
}

@Module({ dynamic: true, store, name: "app" })
class AppDataStore extends VuexModule implements IAppState {
  // ========================================================
  public msal = new MsalData();

  @Mutation
  private UPDATE_MSAL(data: MsalData) {
    this.msal = data;
  }

  @Action
  public UpdateMsal(data: MsalData) {
    this.UPDATE_MSAL(data);
  }

  @Mutation
  private UPDATE_USERNAME(username: string) {
    this.msal.username = username;
  }

  @Action
  public UpdateUsername(username: string) {
    this.UPDATE_USERNAME(username);
  }

  // ========================================================
  public accessTokens: AccessToken[] = [];

  @Mutation
  private UPDATE_ACCESSTOKEN(accessToken: AccessToken) {
    const currentToken = this.accessTokens.find(function (item) {
      return item.audience === accessToken.audience;
    });
    if (currentToken) {
      currentToken.value = accessToken.value;
      currentToken.expiresOn = accessToken.expiresOn;
    } else {
      this.accessTokens.push(accessToken);
    }
  }

  @Action
  public UpdateAccessToken(accessToken: AccessToken) {
    this.UPDATE_ACCESSTOKEN(accessToken);
  }

  // ========================================================
  public isLoading = false;

  @Mutation
  private UPDATE_ISLOADING(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Action
  public UpdateIsLoading(isLoading: boolean) {
    this.UPDATE_ISLOADING(isLoading);
  }

  // ========================================================
  public companyName = "";

  @Mutation
  private UPDATE_COMPANYNAME(companyName: string) {
    this.companyName = companyName;
  }

  @Action
  public UpdateCompanyName(companyName: string) {
    this.UPDATE_COMPANYNAME(companyName);
  }

  // ========================================================
  public userProfile = new UserProfile();

  @Mutation
  private UPDATE_USERPROFILE(userProfile: UserProfile) {
    this.userProfile = userProfile;
  }

  @Action
  public UpdateUserProfile(userProfile: UserProfile) {
    this.UPDATE_USERPROFILE(userProfile);
  }

  // ========================================================
  public ticketList: Ticket[] = [];

  @Mutation
  private UPDATE_TICKETLIST(tickets: Ticket[]) {
    this.ticketList = tickets;
  }

  @Action
  public UpdateTickets(tickets: Ticket[]) {
    this.UPDATE_TICKETLIST(tickets);
  }

  // ========================================================
  public redirectToTicketId = 0;

  @Mutation
  private UPDATE_REDIRECTTOTICKETID(ticketId: number) {
    this.redirectToTicketId = ticketId;
  }

  @Action
  public UpdateRedirectToTicketId(ticketId: number) {
    this.UPDATE_REDIRECTTOTICKETID(ticketId);
  }

  public selectedTicket: Ticket = new Ticket();

  @Mutation
  private UPDATE_SELECTEDTICKET(ticket: Ticket) {
    this.selectedTicket = ticket;
  }

  @Action
  public UpdateSelectedTicket(ticket: Ticket) {
    this.UPDATE_SELECTEDTICKET(ticket);
  }

  @Mutation
  private APPEND_SELECTEDTICKETNOTE(note: TicketNote) {
    this.selectedTicket.notes.push(note);
  }

  @Action
  public AppendSelectedTicketNote(note: TicketNote) {
    this.APPEND_SELECTEDTICKETNOTE(note);
  }

  // ========================================================
  public applicationError: ApplicationError = new ApplicationError();
  public applicationErrorVisible = false;

  @Mutation
  private UPDATE_APPLICATIONERROR(error: ApplicationError) {
    this.applicationError = error;
    this.applicationErrorVisible = true;
  }

  @Action
  public UpdateApplicationError(error: ApplicationError) {
    this.UPDATE_APPLICATIONERROR(error);
  }

  @Mutation
  private HIDE_APPLICATIONERROR() {
    this.applicationErrorVisible = false;
    this.applicationError = new ApplicationError();
  }

  @Action
  public HideApplicationError() {
    this.HIDE_APPLICATIONERROR();
  }
}

const AppModule = getModule(AppDataStore);
export default AppModule;


import AppModule from "@/store/modules/app";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Footer extends Vue {
  get isAuthenticated(): boolean {
    return AppModule.msal.isAuthenticated;
  }

  get supportPhoneNumberUrl(): string {
    return "tel:" + this.supportPhoneNumber;
  }

  get supportPhoneNumber(): string {
    return AppModule.userProfile.cstSupportPhoneNumber;
  }

  get supportEmailAddressUrl(): string {
    return "mailto:" + this.supportEmailAddress;
  }
  get supportEmailAddress(): string {
    return AppModule.userProfile.cstSupportEmailAddress;
  }
}


import AppModule from "@/store/modules/app";
import { Component, Vue, Prop } from "vue-property-decorator";
import { Ticket, TicketPriority, TicketStatus } from "@/services";

@Component
export default class TicketList extends Vue {
  @Prop()
  tickets: Ticket[];

  @Prop()
  loading: boolean;

  pageIndex = 1;
  pageCount = 0;
  itemsPerPage = 10;
  searchValue = "";

  ticketStatus = TicketStatus;
  ticketPriority = TicketPriority;

  headers = [
    { text: "ID", value: "id", width: "80px" },
    {
      text: "Titel",
      align: "start",
      value: "title",
    },
    { text: "Melddatum", value: "createdOn", width: "155px" },
    { text: "Prioriteit", value: "priority", width: "120px" },
    { text: "Status", value: "status", width: "170px" },
  ];

  handleRowClick(value: Ticket): void {
    if (value.isAutotaskTicket) {
      this.$router.push({
        path: "/redirectat",
        query: { ticketId: value.autotaskTicketId.toString() },
      });
    } else {
      this.$router.push({ name: "TicketView", params: { id: value.id.toString() } });
    }
  }
}

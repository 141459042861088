
import { Component, Vue } from "vue-property-decorator";
import { ApplicationError } from "@/services";
import AppModule from "@/store/modules/app";

@Component
export default class ErrorCard extends Vue {
  get error(): ApplicationError {
    return AppModule.applicationError;
  }

  closeError(): void {
    AppModule.HideApplicationError();
  }
}

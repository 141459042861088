
import { Ticket, ApplicationError } from "@/services";
import AppModule from "@/store/modules/app";
import { Component, Vue } from "vue-property-decorator";
import ErrorCard from "../components/ErrorCard.vue";
import NoteTimelineItem from "../components/NoteTimelineItem.vue";
import NoteNew from "../components/NoteNew.vue";
import TicketDetails from "../components/TicketDetails.vue";

@Component({
  components: {
    ErrorCard,
    NoteTimelineItem,
    NoteNew,
    TicketDetails,
  },
})
export default class TicketView extends Vue {
  get ticket(): Ticket {
    return AppModule.selectedTicket;
  }
  get applicationError(): ApplicationError {
    return AppModule.applicationError;
  }

  ticketDetailsLoading = true;

  created(): void {
    const id = parseInt(this.$route.params.id);

    this.$dataService
      .GetTicket(id)
      .then(() => {
        this.ticketDetailsLoading = false;
      })
      .catch((error: ApplicationError): void => {
        console.error(error);
        AppModule.UpdateApplicationError(error);
        this.ticketDetailsLoading = false;
      });
  }
}

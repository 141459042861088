
import { VForm, TicketNote, ApplicationError } from "@/services";
import AppModule from "@/store/modules/app";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class NoteNew extends Vue {
  newMessage = "";
  sendingMessage = false;

  formIsvalid = true;
  messageRules = [(v: string): boolean | string => !!v || "Het bericht bevat nog geen tekst"];

  validateForm(): boolean {
    return (this.$refs.form as VForm).validate();
  }

  handleSubmit(e: HTMLFormElement): void {
    e.preventDefault();

    this.formIsvalid = (this.$refs.form as VForm).validate();
    if (!this.formIsvalid) {
      return;
    }

    this.sendingMessage = true;
    const ticketId = parseInt(this.$route.params.id);

    const ticketNote = TicketNote.newNote(ticketId, AppModule.userProfile);
    ticketNote.body = this.newMessage;

    AppModule.AppendSelectedTicketNote(ticketNote);
    this.$dataService
      .CreateTicketNote(ticketNote)
      .then(() => {
        this.newMessage = "";
        (this.$refs.form as VForm).resetValidation();

        this.sendingMessage = false;
      })
      .catch((error: ApplicationError) => {
        AppModule.UpdateApplicationError(error);
        (this.$refs.form as VForm).resetValidation();

        this.sendingMessage = false;
      });
  }
}

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VForm,{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}},model:{value:(_vm.formIsvalid),callback:function ($$v) {_vm.formIsvalid=$$v},expression:"formIsvalid"}},[_c(VCardTitle,[_vm._v(" Nieuw bericht ")]),_c(VCardText,{staticClass:"pb-0"},[_c(VTextarea,{attrs:{"rows":"4","label":"Schrijf hier uw bericht","solo":"","rules":_vm.messageRules},model:{value:(_vm.newMessage),callback:function ($$v) {_vm.newMessage=$$v},expression:"newMessage"}})],1),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{staticClass:"mr-4 white--text primary",attrs:{"type":"submit","disabled":_vm.sendingMessage},on:{"click":_vm.handleSubmit}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-send ")]),_vm._v(" Verzenden ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
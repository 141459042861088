import MsalWrapper from "@/auth/msal-wrapper";
import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { VueConstructor } from "vue";
import { GraphProfile } from ".";
import { ApplicationError } from "./models/applicationError";

declare module "vue/types/vue" {
  interface Vue {
    $graph: GraphService;
  }
  interface VueConstructor {
    $graph: GraphService;
  }
}

export default class GraphService {
  private graphBaseUrl = process.env.VUE_APP_GRAPHURL || "";

  private async getRequestConfig(
    additionalHeaders?: AxiosRequestHeaders
  ): Promise<AxiosRequestConfig> {
    const msal = MsalWrapper.getInstance();
    const accessToken = await msal.getToken("graph");

    const requestConfig: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    if (additionalHeaders) {
      requestConfig.headers = Object.assign(requestConfig.headers, additionalHeaders);
    }
    return requestConfig;
  }

  public async GetProfile(): Promise<GraphProfile> {
    return axios
      .get(`${this.graphBaseUrl}/me`, await this.getRequestConfig())
      .then((response) => {
        const profile = GraphProfile.fromGraphResponse(response.data);
        return profile;
      })
      .catch((error: Error | AxiosError) => {
        console.error(error);
        throw new ApplicationError(error);
      });
  }
}

export const graphService = new GraphService();

export const GraphServicePlugin = {
  install(Vue: VueConstructor): void {
    Vue.prototype.$graph = new GraphService();
  },
};


import AppModule from "@/store/modules/app";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Nav extends Vue {
  async signOut(): Promise<void> {
    await this.$msal.signOut();
  }

  get isAuthenticated(): boolean {
    return AppModule.msal.isAuthenticated;
  }

  get isProfileLoaded(): boolean {
    return AppModule.userProfile.emailAddress.length > 0;
  }

  gotoRedirectAt(): void {
    this.$router.push({ path: "/redirectat" });
  }
}

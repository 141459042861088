import * as msal from "@azure/msal-browser";

export const msalConfig: msal.Configuration = {
  auth: {
    clientId: "-- actual value set in .env --",
    authority: "https://login.microsoftonline.com/organizations",
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage", //sessionStorage, localStorage
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: msal.LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message);
            return;
          case msal.LogLevel.Info:
            console.info(message);
            return;
          case msal.LogLevel.Verbose:
            console.debug(message);
            return;
          case msal.LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      piiLoggingEnabled: false,
      logLevel: msal.LogLevel.Warning,
    },
  },
};

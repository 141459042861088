import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,[_c(VCol,[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"600"}},[_c(VCardTitle,[_vm._v("Migratie")]),_c(VCardText,{staticClass:"black--text"},[_c('p',[_vm._v("Beste (eind)gebruiker,")]),_c('p',[_vm._v(" Vanaf heden kun jij als (eind)gebruiker gebruik maken van Autotask, hét platform voor alle incidenten, wijzigingen en vragen! ")]),_c('p',[_vm._v("Wat houdt deze migratie voor jou precies in?")]),_c('p',[_vm._v(" • Reeds aangemaakte incidenten, wijzigingen en vragen zijn nog in te zien via het eindgebruikers- en klantenportaal. ")]),_c('p',[_vm._v(" • Voor het aanmaken van tickets kan je gebruik maken van het nieuwe "),_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":_vm.gotoRedirectAt}},[_vm._v("Klantenportaal")]),_vm._v(". ")]),_c('p',[_vm._v(" • Voor inzicht in facturen kan je gebruik maken van het "),_c('a',{attrs:{"href":"https://factuur.uicts.nl","target":"_blank"}},[_vm._v("Factuurportaal")]),_vm._v(". ")]),_c('p',[_vm._v(" Mocht je vragen hebben, dan kun je terecht bij onze servicedesk door te bellen met het nummer "),_c('a',{attrs:{"href":_vm.supportPhoneNumberUrl}},[_vm._v(_vm._s(_vm.supportPhoneNumber))]),_vm._v(". ")]),_c('p',[_vm._v(" Met vriendelijke groet, "),_c('br'),_c('br'),_vm._v(" Unica ICT Solutions ")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }

import { Component, Vue } from "vue-property-decorator";
import AppModule from "@/store/modules/app";
import {
  DayNames,
  Ticket,
  VForm,
  TicketIssueTypeList,
  TicketUrgencyList,
  TicketUrgency,
  TicketIssueType,
  ApplicationError,
} from "@/services";
import ErrorCard from "../components/ErrorCard.vue";

@Component({
  components: {
    ErrorCard,
  },
})
export default class TicketNew extends Vue {
  formIsvalid = false;
  ticket: Ticket = new Ticket();
  title = "";

  selectedIssueType: TicketIssueType = TicketIssueType.Empty();
  selectedTicketUrgency: TicketUrgency = TicketUrgency.Empty();
  blockSubmit = false;

  files: File[] = [];

  otherIssueTypeLabel = "-- Iets anders --";
  issueTypeList: TicketIssueType[] = TicketIssueTypeList;
  ticketUrgencyList: TicketUrgency[] = TicketUrgencyList;

  descriptionLabel =
    "Geef een korte omschrijving van uw vraag of probleem en of deze al eerder is voorgekomen";

  days = DayNames;
  daysAvailable = DayNames;

  get isUrgencyCritical(): boolean {
    return this.selectedTicketUrgency.label == "Kritiek";
  }
  get isUrgencyHigh(): boolean {
    return this.selectedTicketUrgency.label == "Hoog";
  }

  get cstSupportPhoneNumber(): string {
    return AppModule.userProfile.cstSupportPhoneNumber;
  }
  get cstSupportPhoneNumberUrl(): string {
    return "tel:" + AppModule.userProfile.cstSupportPhoneNumber;
  }

  // ==========================================================================
  titleRules = [(v: string): boolean | string => !!v || "Onderwerp is verplicht"];
  descriptionRules = [(v: string): boolean | string => !!v || "De omschrijving is verplicht"];
  emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  emailRules = [
    (v: string): boolean | string => !v || this.emailRegex.test(v) || "E-mailadres is ongeldig",
  ];
  phoneRegex = /(^\+\d{2}|^00\d{2}|^0)([\d]{9}$|[\d\-\s]{10}$)/;
  phoneRules = [
    (v: string): boolean | string => !v || this.phoneRegex.test(v) || "Telefoonnummer is ongeldig",
  ];
  issueTypeListRules = [
    (v: TicketIssueType): boolean | string =>
      !!v.label || `Selecteer een optie uit de lijst of kies voor '${this.otherIssueTypeLabel}'`,
  ];
  ticketUrgencyListRules = [
    (v: TicketUrgency): boolean | string => !!v.label || `Selecteer een optie uit de lijst`,
    (v: TicketUrgency): boolean | string =>
      v.label != "Kritiek" || `Kritieke urgentie telefonisch melden`,
  ];

  get daysAvailableRules(): Array<boolean | string> {
    const validValues = this.daysAvailable.filter((value) => {
      if (value) {
        return value;
      }
    });
    return [validValues.length > 0 || ``];
  }

  get fileRules(): Array<boolean | string> {
    let filesizeValid = true;
    let filesizeTotalValid = true;
    let fileTypeValid = true;

    let filesizeTotal = 0;
    const fileAccept = this.fileAccept.replace(" ", "").split(",");

    for (const file of this.files) {
      if (fileAccept.indexOf(file.type) === -1) {
        fileTypeValid = false;
        break;
      }

      filesizeTotal += file.size;

      if (filesizeTotal > 10000000) {
        filesizeTotalValid = false;
        break;
      }

      if (file.size > 4000000) {
        filesizeValid = false;
        break;
      }
    }
    return [
      fileTypeValid || "Ongeldig bestandsformaat gekozen",
      filesizeValid || "Bestandsgrote moet minder zijn dan 4 MB",
      filesizeTotalValid || "De totale bestandsgrote moet minder dan 10 MB zijn",
    ];
  }

  fileAccept =
    "image/png, image/jpeg, application/pdf, " +
    "application/word, application/vnd.openxmlformats-officedocument.wordprocessingml.document, " +
    "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  created(): void {
    this.ticket.contactName = AppModule.userProfile.name;
    this.ticket.contactEmail = AppModule.userProfile.emailAddress;
    this.ticket.contactPhone = AppModule.userProfile.phone;
    if (AppModule.userProfile.daysAvailable) {
      this.daysAvailable = AppModule.userProfile.daysAvailable.split(";");
    }
  }

  validateForm(): boolean {
    return (this.$refs.form as VForm).validate();
  }

  handleCancel(): void {
    (this.$refs.form as VForm).resetValidation();
    this.$router.replace({ name: "Home" });
  }

  async handleSubmit(): Promise<void> {
    this.formIsvalid = (this.$refs.form as VForm).validate();
    if (!this.formIsvalid) {
      return;
    }

    this.blockSubmit = true;
    AppModule.userProfile.phone = this.ticket.contactPhone;
    AppModule.userProfile.daysAvailable = this.daysAvailable.join(";");

    //Processing new ticket
    const newTicket = Object.assign({}, this.ticket);

    const validDaysAvailable = this.daysAvailable.filter((value) => {
      if (value) {
        return value;
      }
    });

    newTicket.description += `

Urgentie: ${this.selectedTicketUrgency.label} - ${this.selectedTicketUrgency.description}
Dagen beschikbaar: ${validDaysAvailable.join(", ")}
  `;

    newTicket.daysAvailable = validDaysAvailable.join(";");
    newTicket.priorityId = this.selectedTicketUrgency.priorityId;

    if (this.selectedIssueType.label == this.otherIssueTypeLabel) {
      newTicket.title = this.title;
    } else {
      newTicket.categoryId = this.selectedIssueType.categoryId;
      newTicket.classificationId = this.selectedIssueType.classificationId;
      newTicket.title = `${this.selectedIssueType.titlePrefix} ${this.selectedIssueType.label}`;
    }

    this.createTicket(newTicket);
  }

  async createTicket(ticket: Ticket): Promise<void> {
    await this.$dataService
      .CreateTicket(ticket)
      .then(async (resultTicket: Ticket) => {
        if (this.files.length) {
          let formData = new FormData();
          for (let i = 0; i < this.files.length; i++) {
            formData.append("files", this.files[i], this.files[i].name);
          }

          await this.$dataService.UploadFiles(resultTicket.id, formData);
          this.files = [];
        }

        this.$router.replace({
          name: "TicketView",
          params: { id: resultTicket.id.toString() },
        });
      })
      .catch((error: ApplicationError) => {
        console.error(error);
        AppModule.UpdateApplicationError(error);
        this.blockSubmit = false;
      });
  }
}

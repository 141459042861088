
import { Component, Vue } from "vue-property-decorator";
import { ApplicationError, DayNames, GraphProfile } from "@/services";
import AppModule from "@/store/modules/app";
import ErrorCard from "../components/ErrorCard.vue";

@Component({
  components: {
    ErrorCard,
  },
})
export default class Profile extends Vue {
  profile = new GraphProfile();
  userProfile = AppModule.userProfile;

  daysAvailable(): string {
    const daysAvailable: string[] = [];
    if (this.userProfile.daysAvailable) {
      DayNames.forEach((dayName) => {
        if (this.userProfile.daysAvailable.includes(dayName)) {
          daysAvailable.push(dayName);
        }
      });
    }
    return daysAvailable.join(", ");
  }

  created(): void {
    this.$graph
      .GetProfile()
      .then((profile: GraphProfile) => {
        this.profile = profile;
      })
      .catch((error: ApplicationError) => {
        console.error(error);
        AppModule.UpdateApplicationError(error);
      });
  }
}

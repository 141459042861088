import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,[_c(VCol,[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"600"}},[_c(VCardTitle,{staticClass:"text-h4 text-center"},[_vm._v("Inloggen mislukt")]),_c(VCardText,{staticClass:"black--text"},[_vm._v("De inlogpoging is helaas niet geslaagd. Je kunt het opnieuw proberen of telefonisch contact met ons opnemen.")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }

import { Component, Vue } from "vue-property-decorator";
import TicketList from "../components/TicketList.vue";
import CountWidget from "../components/CountWidget.vue";
import ErrorCard from "@/components/ErrorCard.vue";
import { ApplicationError, Ticket, TicketStatus } from "@/services";
import AppModule from "@/store/modules/app";

class HomeVueProperties {
  public isLoading = false;
  public tickets: Ticket[] = [];
  public listState = true;

  public ticketCountOpen = 0;
  public ticketCountInProgress = 0;
  public ticketCountWaitingForCustomer = 0;
  public ticketCountSolved = 0;
  public ticketCountClosed = 0;
}

@Component({
  components: {
    TicketList,
    CountWidget,
    ErrorCard,
  },
})
export default class Home extends Vue {
  props: HomeVueProperties = new HomeVueProperties();

  get loading(): boolean {
    return this.props.isLoading;
  }

  set loading(loading: boolean) {
    this.props.isLoading = loading;
  }

  get tickets(): Ticket[] {
    return this.props.tickets;
  }

  set tickets(tickets: Ticket[]) {
    this.props.tickets = tickets;
  }

  btnStateOutlined = false;
  btnTextActive = "Actief";
  btnTextInactive = "Gesloten";
  btnStateText = this.btnTextActive;

  setListState(): void {
    this.props.listState = !this.props.listState;
    if (this.props.listState) {
      this.btnStateText = this.btnTextActive;
      this.btnStateOutlined = false;
    } else {
      this.btnStateText = this.btnTextInactive;
      this.btnStateOutlined = true;
    }

    this.loadTickets();
  }

  getTicketCountByStatus(status: TicketStatus): number {
    return this.props.tickets.filter((item) => {
      return item.status == status;
    }).length;
  }

  setCountVars(): void {
    this.props.ticketCountOpen = this.getTicketCountByStatus(TicketStatus.Open);
    this.props.ticketCountInProgress = this.getTicketCountByStatus(TicketStatus["In behandeling"]);
    this.props.ticketCountWaitingForCustomer = this.getTicketCountByStatus(
      TicketStatus["Wachten op klant"]
    );
    this.props.ticketCountSolved = this.getTicketCountByStatus(TicketStatus.Opgelost);
    this.props.ticketCountClosed = this.getTicketCountByStatus(TicketStatus.Gesloten);
  }

  loadTickets(): void {
    this.loading = true;

    this.$dataService
      .GetTickets(this.props.listState)
      .then((tickets) => {
        this.tickets = tickets;
        this.setCountVars();
        this.loading = false;
      })
      .catch((error) => {
        console.error(error);
        const errorMessage =
          "De benodigde informatie kan niet wordt opgevraagd. Controleer uw internet verbinding en probeer het opnieuw.";
        AppModule.UpdateApplicationError(new ApplicationError(error, errorMessage));
        this.loading = false;
      });
  }

  created(): void {
    if (AppModule.redirectToTicketId > 0) {
      const ticketId = AppModule.redirectToTicketId.toString();
      AppModule.UpdateRedirectToTicketId(0);
      this.$router.push({
        name: "TicketView",
        params: { id: ticketId },
      });
    } else {
      this.loadTickets();
    }
  }
}

import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";

export class GraphProfile {
  public displayName = "";
  public jobTitle = "";
  public department = "";
  public companyName = "";
  public mail = "";
  public mobilePhone = "";

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static fromGraphResponse(data: MicrosoftGraph.User): GraphProfile {
    const profile = new this();

    profile.displayName = data.displayName || "";
    profile.jobTitle = data.jobTitle || "";
    profile.department = data.department || "";
    profile.companyName = data.companyName || "";
    profile.mail = data.mail || "";
    profile.mobilePhone = data.mobilePhone || "";

    return profile;
  }
}

export class UserProfile {
  public name = "";
  public emailAddress = "";
  public daysAvailable = "";
  public phone = "";
  public companyName = "";
  public ofbKltId = 0;

  public cstName = "";
  public cstSupportPhoneNumber = "088-2222 888";
  public cstSupportEmailAddress = "ictservicedesk@unica.nl";

  public altCreateTicketUrl = "";
  public useAutotask = false;

  public static fromApiResponse(input: UserProfile): UserProfile {
    const userProfile = new this();

    userProfile.name = input.name;
    userProfile.emailAddress = input.emailAddress;
    userProfile.daysAvailable = input.daysAvailable;
    userProfile.phone = input.phone;
    userProfile.companyName = input.companyName;
    userProfile.ofbKltId = input.ofbKltId;

    userProfile.cstName = input.cstName;
    userProfile.cstSupportPhoneNumber = input.cstSupportPhoneNumber;
    userProfile.cstSupportEmailAddress = input.cstSupportEmailAddress;

    userProfile.altCreateTicketUrl = input.altCreateTicketUrl;
    userProfile.useAutotask = input.useAutotask;

    return userProfile;
  }

  public getUseAutotask(): boolean {
    if (this.useAutotask) {
      return true;
    }
    return false;
  }

  public getAltCreateTicketUrl(): boolean {
    if (this.altCreateTicketUrl) {
      return true;
    }
    return false;
  }
}


import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CountWidget extends Vue {
  @Prop()
  label: string;

  @Prop()
  value: number;
}


import AppModule from "@/store/modules/app";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Login extends Vue {
  get isLoading(): boolean {
    return AppModule.isLoading;
  }

  get isAuthenticated(): boolean {
    return AppModule.msal.isAuthenticated;
  }

  created(): void {
    if (this.$route.query.ticketId && !isNaN(+this.$route.query.ticketId)) {
      const ticketId = +this.$route.query.ticketId;
      AppModule.UpdateRedirectToTicketId(ticketId);
      console.log(`TicketId: ${ticketId}`);
    }
  }

  signIn(): void {
    this.$msal.signIn();
  }
}

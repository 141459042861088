import Vue from "vue";
import { TicketPriority, TicketStatus } from "@/services";

const leadingZero = (num: number): string => `0${num}`.slice(-2);

Vue.filter("dateFilter", function (value: string) {
  if (!value) return "";
  const d = new Date(value);
  const date = `${leadingZero(d.getDate())}-${leadingZero(d.getMonth() + 1)}-${d.getFullYear()}`;
  const time = `${d.getHours()}:${leadingZero(d.getMinutes())}`;
  return `${date} ${time}`;
});

Vue.filter("ticketPriorityColor", function (priority: TicketPriority): string {
  switch (priority) {
    case TicketPriority.Standaard:
      return "green";
    case TicketPriority.Kritiek:
      return "#ff0000";
    case TicketPriority.Hoog:
      return "#ff6600";
    case TicketPriority.Laag:
      return "blue";
    default:
      return "gray";
  }
});

Vue.filter("ticketStatusIcon", function (status: TicketStatus): string {
  switch (status) {
    case TicketStatus["Wachten op klant"]:
      return "mdi-help";
    default:
      return "";
  }
});

import axios, { AxiosError } from "axios";

export class ApplicationError {
  public originError = {};
  public name = "";
  public message = "";

  constructor(error?: Error | AxiosError, overrideMessage = "") {
    if (error) {
      this.originError = error;

      if (overrideMessage) {
        this.message = overrideMessage;
      } else {
        if (axios.isAxiosError(error)) {
          this.name = "Communicatie met API";
          if (error.response) {
            this.message = error.response.data as string;
          } else {
            this.message = "Onbekende fout";
          }
        } else {
          this.name = error.name;
          this.message = error.message;
        }
      }
    }
  }
}

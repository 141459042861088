import * as msal from "@azure/msal-browser";
import { msalConfig } from "./msal-config";
import { MsalData, MsalOptions } from "./msal-types";
import AppModule from "@/store/modules/app";
import { VueConstructor } from "vue";
import { AccessToken } from ".";

// ============================================================================
export default class MsalWrapper {
  private msalInstance: msal.PublicClientApplication;
  private pluginOptions: MsalOptions;

  private static _instance: MsalWrapper;

  constructor(options?: MsalOptions) {
    if (!options) {
      throw new Error("MsalOptions must be specified");
    }

    this.pluginOptions = options;
    msalConfig.auth.clientId = options.clientId;
    this.msalInstance = new msal.PublicClientApplication(msalConfig);

    this.initialize();
  }

  private initialize() {
    this.msalInstance
      .handleRedirectPromise()
      .then(() => {
        this.selectAccount();
      })
      .catch((error: unknown) => {
        console.error(error);
      });
  }

  private getTokenRequest(audience: string): msal.RedirectRequest {
    switch (audience) {
      case "graph":
        return { scopes: ["user.read", "openid", "profile"] };

      default:
        return { scopes: this.pluginOptions.scopes };
    }
  }

  private selectAccount() {
    const currentAccounts = this.msalInstance.getAllAccounts();

    if (currentAccounts.length === 0) {
      return;
    } else if (currentAccounts.length > 1) {
      console.warn("Multiple accounts detected.");
      return;
    } else if (currentAccounts.length === 1) {
      AppModule.UpdateUsername(currentAccounts[0].username);
      const audience = "graph";
      this.getTokenRedirect(this.getTokenRequest(audience)).then((response) => {
        this.processAuthenticationResult(response, audience);
        this.pluginOptions.onRedirectCallback(AppModule.msal);
      });
    }
  }

  private processAuthenticationResult(
    response: void | msal.AuthenticationResult | undefined,
    audience: string
  ) {
    if (response === null || response === undefined || response.account === null) {
      // TODO: Process incorrect response
      return;
    }

    const decodedToken = JSON.parse(
      atob(msal.StringUtils.decodeAuthToken(response.accessToken)["JWSPayload"])
    );

    const accessToken = new AccessToken(audience, response.accessToken, response.expiresOn);

    AppModule.UpdateAccessToken(accessToken);

    const data = new MsalData();
    data.username = response.account.username;
    data.isAuthenticated = this.getIsAuthenticated();
    data.authenticationMethodReference = decodedToken.amr;
    data.usedMfa = decodedToken.amr.includes("mfa") || decodedToken.amr.includes("rsa");
    AppModule.UpdateMsal(data);

    console.log("Authentication processing is done...");
  }

  private async getTokenRedirect(request: msal.RedirectRequest) {
    const account = this.msalInstance.getAccountByUsername(AppModule.msal.username);

    if (account === null) {
      console.error("Unable to get Account by username");
      return;
    }

    request.account = account;

    return this.msalInstance.acquireTokenSilent(request).catch((error: unknown) => {
      console.warn("silent token acquisition fails. acquiring token using redirect");
      if (error instanceof msal.InteractionRequiredAuthError) {
        return this.msalInstance.acquireTokenRedirect(request);
      } else {
        console.warn(error);
      }
    });
  }

  // Public methodes

  public static getInstance(options?: MsalOptions): MsalWrapper {
    if (options) {
      this._instance = new this(options);
    }
    return this._instance;
  }

  public signIn(): void {
    this.msalInstance.loginRedirect(this.getTokenRequest("graph"));
  }

  public async signOut(): Promise<void> {
    const logoutRequest = {
      account: this.msalInstance.getAccountByUsername(AppModule.msal.username),
      postLogoutRedirectUri: msalConfig.auth.redirectUri,
    };

    await this.msalInstance.logoutRedirect(logoutRequest);
  }

  public async getToken(audience: string, depth = 0): Promise<string> {
    const currentToken = AppModule.accessTokens.find(function (item) {
      return item.audience === audience;
    });
    if (currentToken && currentToken.expiresOn > new Date()) {
      return currentToken.value;
    }

    if (depth > 0) {
      console.log("Preventing authentication loop...");
      return "";
    }

    return await this.getTokenRedirect(this.getTokenRequest(audience))
      .then((response) => {
        this.processAuthenticationResult(response, audience);
        return this.getToken(audience, 1);
      })
      .catch((error) => {
        console.log(error);
        return "";
      });
  }

  public getIsAuthenticated(): boolean {
    const accounts = this.msalInstance.getAllAccounts();
    return accounts && accounts.length > 0;
  }
}

export const MsalPlugin = {
  install(Vue: VueConstructor, options: MsalOptions): void {
    Vue.prototype.$msal = MsalWrapper.getInstance(options);
  },
};

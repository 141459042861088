
import {
  ApplicationError,
  Ticket,
  TicketNote,
  TicketPriority,
  TicketStatus,
  VForm,
} from "@/services";
import AppModule from "@/store/modules/app";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TicketDetails extends Vue {
  @Prop()
  ticket: Ticket;

  @Prop()
  isLoading: boolean;

  showImageDialog = false;
  imageSource = "";

  ticketStatus = TicketStatus;
  ticketPriority = TicketPriority;

  async downloadFile(fileId: number, fileName: string): Promise<void> {
    var extension = fileName.split(".").pop();
    switch (extension) {
      case "jpg":
      case "gif":
      case "png":
        this.imageSource = await this.$dataService.DownloadFile(
          this.ticket.id,
          fileId,
          fileName,
          true
        );
        this.showImageDialog = true;
        break;

      default:
        this.$dataService.DownloadFile(this.ticket.id, fileId, fileName);
        break;
    }
  }

  formIsvalid = true;
  blockSubmit = false;
  files: File[] = [];

  get fileRules(): Array<boolean | string> {
    let filesizeValid = true;
    let filesizeTotalValid = true;
    let fileTypeValid = true;

    let filesizeTotal = 0;
    const fileAccept = this.fileAccept.replace(" ", "").split(",");

    for (const file of this.files) {
      if (fileAccept.indexOf(file.type) === -1) {
        fileTypeValid = false;
        break;
      }

      filesizeTotal += file.size;

      if (filesizeTotal > 10000000) {
        filesizeTotalValid = false;
        break;
      }

      if (file.size > 4000000) {
        filesizeValid = false;
        break;
      }
    }
    return [
      fileTypeValid || "Ongeldig bestandsformaat gekozen",
      filesizeValid || "Bestandsgrote moet minder zijn dan 4 MB",
      filesizeTotalValid || "De totale bestandsgrote moet minder dan 10 MB zijn",
    ];
  }

  fileAccept =
    "image/png, image/jpeg, application/pdf, " +
    "application/word, application/vnd.openxmlformats-officedocument.wordprocessingml.document, " +
    "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  validateForm(): boolean {
    return (this.$refs.form as VForm).validate();
  }

  async handleSubmit(): Promise<void> {
    this.formIsvalid = (this.$refs.form as VForm).validate();
    if (!this.formIsvalid) {
      return;
    }

    this.blockSubmit = true;

    if (this.files.length) {
      let formData = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        formData.append("files", this.files[i], this.files[i].name);
      }

      await this.$dataService
        .UploadFiles(this.ticket.id, formData)
        .then(async (uploadedFiles) => {
          const newNote = TicketNote.newNote(this.ticket.id, AppModule.userProfile);
          const name = AppModule.userProfile.name;

          let message = `Bestand(en) geupload door ${name}:`;
          for (const filename of uploadedFiles) {
            message += `\n - ${filename}`;
          }
          newNote.body = message;
          await this.$dataService.CreateTicketNote(newNote);
        })
        .catch((error: ApplicationError) => {
          AppModule.UpdateApplicationError(error);
        });

      this.files = [];

      await this.$dataService.GetTicket(this.ticket.id);
    }
    this.blockSubmit = false;
  }
}


import { Component, Vue } from "vue-property-decorator";
import NavigationBar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";

@Component({
  components: {
    NavigationBar,
    Footer,
  },
})
export default class App extends Vue {}

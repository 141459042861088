import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"mx-auto"},[_c('ErrorCard'),(!_vm.applicationError.name)?_c(VRow,[_c(VCol,{attrs:{"md":"6","cols":"12"}},[_c('TicketDetails',{attrs:{"isLoading":_vm.ticketDetailsLoading,"ticket":_vm.ticket}})],1),_c(VCol,{attrs:{"md":"6","cols":"12"}},[_c(VCard,{attrs:{"color":"#2b4c77","outlined":"","loading":_vm.ticketDetailsLoading}},[(!_vm.ticket.isClosed)?_c(VCard,{attrs:{"elevation":"5"}},[_c('NoteNew')],1):_vm._e(),_c(VTimeline,{attrs:{"dense":""}},_vm._l((_vm.ticket.notes.slice().reverse()),function(note,key){return _c('div',{key:key},[_c('NoteTimelineItem',{attrs:{"note":note}})],1)}),0)],1)],1)],1):_vm._e(),_c(VSpacer)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { TicketIssueType, TicketUrgency } from ".";

export const DayNames = ["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag"];

// txtOmschrijving	                  txtCode
// z. EG - Microsoft 365	              40
// z. EG - SharePoint	                  41
// z. EG - Teams	                      42
// z. EG - Teams Telefonie	            43
// z. EG - Cloud Printing (Printix)	    44
// z. EG - Desktops & Laptops	          38
// z. EG - Mobile (Microsoft MDM)	      39
// z. EG - Thin Client	                45
// z. EG - Virtual Desktop (Citrix)	    36
// z. EG - Virtual Desktop (Microsoft)	35
// z. EG - Web Desktop (Workspace 365)	37
// z. EG - Overig	                      200

export const TicketIssueTypeList: TicketIssueType[] = [
  new TicketIssueType("Bedrijfsapplicaties (ERP, CRM, ...)", "[Applicatie]", "200"),
  new TicketIssueType("Office applicaties (Word, Excel, Outlook, ...)", "[Office]", "40"),
  new TicketIssueType("Microsoft Teams", "[Teams]", "42"),
  new TicketIssueType("Microsoft Teams Telefonie", "[Teams Tel]", "43"),
  new TicketIssueType("Mobiele devices (Mobiele telefoon, tablet, ...)", "[Mobiel]", "39"),
  new TicketIssueType("Desktop of Laptop", "[Device]", "38"),
  new TicketIssueType("Toegang tot device of applicatie", "[Toegang]", "200"),
  new TicketIssueType("-- Iets anders --", "", "200"),
];

export const TicketUrgencyList: TicketUrgency[] = [
  new TicketUrgency("Kritiek", "Ik en mijn collega's kunnen niet verder werken", 123),
  new TicketUrgency("Hoog", "Ik en evt. mijn collega's ondervinden hinder in het werk", 123),
  new TicketUrgency("Standaard", "Ik kan wel werken, maar niet optimaal.", 123),
];

import { UserProfile } from ".";

export enum TicketStatus {
  Onbekend,
  Open,
  "In behandeling" = 2,
  "Wachten op klant" = 3,
  Opgelost,
  Gesloten,
}

export enum TicketPriority {
  Onbekend,
  Kritiek,
  Hoog,
  Standaard,
  Laag,
}

export class Ticket {
  public id = 0;
  public title = "";
  public description = "";
  public progress = "";
  public solution = "";
  public classification = "";
  public priority = TicketPriority.Onbekend;
  public status = TicketStatus.Onbekend;
  public contactName = "";
  public contactEmail = "";
  public contactPhone = "";
  public createdOn = new Date();
  public solvedOn = new Date();
  public completedOn = new Date();
  public daysAvailable = "";
  public isClosed = false;
  public isAutotaskTicket = false;
  public autotaskTicketId = -1;

  public notes: TicketNote[] = [];
  public files: TicketFile[] = [];

  public categoryId = "200";
  public classificationId = 64;
  public priorityId = 123;
}

export class TicketNote {
  public id = 0;
  public ticketId = 0;
  public contactName = "";
  public contactEmail = "";
  public createdOn = new Date();
  public body = "";
  public fromSupplier = false;

  //Frontend properties
  public isSolution = false;

  static newNoteBody(body: string): TicketNote {
    return <TicketNote>{
      body: body,
    };
  }

  static newNoteSolution(ticket: Ticket): TicketNote {
    return <TicketNote>{
      body: ticket.solution,
      createdOn: ticket.solvedOn,
      fromSupplier: true,
      isSolution: true,
    };
  }

  static newNote(ticketId: number, profile: UserProfile): TicketNote {
    return <TicketNote>{
      ticketId: ticketId,
      contactName: profile.name,
      contactEmail: profile.emailAddress,
      createdOn: new Date(),
    };
  }
}

export class TicketFile {
  public id = 0;
  public fileName = "";
  public createdOn = new Date();
}

export class TicketIssueType {
  public label = "";
  public titlePrefix = "";
  public categoryId = "";
  public classificationId = 64;

  public static Empty(): TicketIssueType {
    return new TicketIssueType("", "", "", 64);
  }

  constructor(label: string, titlePrefix: string, categoryId: string, classificationId = 64) {
    this.label = label;
    this.titlePrefix = titlePrefix;
    this.categoryId = categoryId;
    this.classificationId = classificationId;
  }
}

export class TicketUrgency {
  public label = "";
  public description = "";
  public priorityId = 123;

  public static Empty(): TicketUrgency {
    return new TicketUrgency("", "", 123);
  }

  constructor(label: string, description: string, priorityId: number) {
    this.label = label;
    this.description = description;
    this.priorityId = priorityId;
  }
}

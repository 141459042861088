import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,[_c(VCol,[_c(VCard,{staticClass:"mx-auto",attrs:{"tile":"","max-width":"600","loading":_vm.isLoading}},[_c(VCardTitle,[_vm._v("Unica ICT Solutions - Client Portal")]),_c(VCardText,{directives:[{name:"show",rawName:"v-show",value:(_vm.isError),expression:"isError"}],staticClass:"text-center black--text"},[_c('p',[_vm._v(" Er is helaas iets fout gegaan. Controleer het emailadres en probeer het opnieuw of neem contact op met onze servicedesk. ")])]),_c(VCardText,{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],staticClass:"text-center black--text"},[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}},model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[_c(VTextField,{attrs:{"label":"Email adres","rules":_vm.emailRules,"solo":""},model:{value:(_vm.emailAddress),callback:function ($$v) {_vm.emailAddress=$$v},expression:"emailAddress"}}),_c(VBtn,{staticClass:"mr-4 white--text primary",attrs:{"type":"submit","disabled":!_vm.formIsValid},on:{"click":_vm.handleSubmit}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-login ")]),_vm._v(" Inloggen ")],1)],1)],1),_c(VCardText,{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"text-center black--text"},[_c('p',[_vm._v("De client portal wordt geladen, u wordt doorverwezen. Even geduld a.u.b.")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
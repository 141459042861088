
import AppModule from "@/store/modules/app";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Migration extends Vue {
  gotoRedirectAt(): void {
    const emailAddress = AppModule.userProfile.emailAddress;
    this.$router.push({ path: "/redirectat", query: { email: emailAddress } });
  }

  get supportPhoneNumberUrl(): string {
    return "tel:" + this.supportPhoneNumber;
  }

  get supportPhoneNumber(): string {
    return AppModule.userProfile.cstSupportPhoneNumber;
  }
}

import MsalWrapper from "./msal-wrapper";

declare module "vue/types/vue" {
  interface Vue {
    $msal: MsalWrapper;
    // TODO Fix this es lint ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    prototype: any;
  }
  interface VueConstructor {
    $msal: MsalWrapper;
  }
}

export interface MsalOptions {
  clientId: string;
  scopes: string[];
  onRedirectCallback: (data: MsalData) => void;
}

export class MsalData {
  public username = "";
  public isAuthenticated = false;
  public usedMfa = false;
  public authenticationMethodReference = "";
}

export class AccessToken {
  public audience: string;
  public value: string;
  public expiresOn: Date;

  constructor(audience: string, value: string, expiresOn: Date | null) {
    this.audience = audience;
    this.value = value;
    this.expiresOn = expiresOn || new Date();
  }
}

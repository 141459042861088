import Vue from "vue";
import VueRouter, { NavigationGuard } from "vue-router";
import Login from "../views/Login.vue";
import NoMfa from "../views/NoMfa.vue";
import Migration from "../views/Migration.vue";
import RedirectAt from "../views/RedirectAt.vue";
import NoProfile from "../views/NoProfile.vue";
import NotAuth from "../views/NotAuth.vue";
import Help from "../views/Help.vue";
import Home from "../views/Home.vue";
import TicketView from "../views/TicketView.vue";
import Profile from "../views/Profile.vue";
import TicketNew from "../views/TicketNew.vue";
import { IAppState } from "@/store/modules/app";

Vue.use(VueRouter);

const authGuard: NavigationGuard = function (to, from, next) {
  const store: IAppState = router.app.$store.state.app;
  if (to.path === "/redirectat") {
    next();
  }

  if (!store.msal.isAuthenticated) {
    router.replace({ name: "Login" }).catch();
  }

  if (to.path === "/profile") {
    next();
  } else if (!store.msal.usedMfa && to.path !== "/nomfa") {
    router.replace({ name: "NoMfa" }).catch();
  } else if (to.path == "/ticket/new" && store.userProfile.getUseAutotask()) {
    router.replace({ name: "Migration" }).catch();
  } else if (to.path == "/ticket/new" && store.userProfile.getAltCreateTicketUrl()) {
    router.replace({ name: "RedirectCreateTicket" }).catch();
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/notAuth",
    name: "NotAuth",
    component: NotAuth,
  },
  {
    path: "/help",
    name: "Help",
    component: Help,
  },
  {
    path: "/nomfa",
    name: "NoMfa",
    component: NoMfa,
  },
  {
    path: "/migration",
    name: "Migration",
    component: Migration,
  },
  {
    path: "/redirectat",
    name: "RedirectAt",
    component: RedirectAt,
  },
  {
    path: "/noprofile",
    name: "NoProfile",
    component: NoProfile,
    beforeEnter: authGuard,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    beforeEnter: authGuard,
  },
  {
    path: "/ticket/new",
    name: "TicketNew",
    component: TicketNew,
    beforeEnter: authGuard,
  },
  {
    path: "/ticket/:id",
    name: "TicketView",
    component: TicketView,
    beforeEnter: authGuard,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    beforeEnter: authGuard,
  },
  {
    path: "/redirect/createticket",
    name: "RedirectCreateTicket",
    beforeEnter() {
      const store: IAppState = router.app.$store.state.app;
      window.open(store.userProfile.altCreateTicketUrl, "_blank");
    },
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  routes: [...routes],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;

import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,[_c(VCol,[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"600"}},[_c(VCardTitle,[_vm._v("MFA verplicht")]),_c(VCardText,{staticClass:"black--text"},[_vm._v(" Voor jou en onze (digitale) veiligheid is het gebruik van Multi Factor Authenticatie, bijvoorbeeld met een token of de Microsoft Authenticator app, verplicht voor toegang tot dit portaal ")]),_c(VCardText,{staticClass:"black--text"},[_vm._v("Je kunt contact opnemen met het systeembeheer van jouw organisatie om je hierbij te helpen.")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from "vue";
import App from "./App.vue";
import store from "@/store";
import router from "./plugins/router";
import "./registerServiceWorker";
import { MsalData, MsalOptions, MsalPlugin } from "./auth";
import vuetify from "./plugins/vuetify";
import { GraphServicePlugin, DataServicePlugin, GraphProfile, ApplicationError } from "./services";
import { dataService } from "./services/dataService";
import { graphService } from "./services/graphService";
import AppModule from "./store/modules/app";
import "./plugins/filters";

Vue.config.productionTip = false;

const options: MsalOptions = {
  clientId: process.env.VUE_APP_CLIENTID as string,
  scopes: [process.env.VUE_APP_ACCESSTOKENSCOPE as string],
  onRedirectCallback: (data: MsalData) => {
    AppModule.UpdateIsLoading(true);

    if (router.currentRoute.path.toLowerCase().includes("redirectat")) {
      AppModule.UpdateIsLoading(false);
      return;
    }

    if (!data.isAuthenticated) {
      router.replace({ name: "NotAuth" });
    } else if (!data.usedMfa) {
      router.replace({ name: "NoMfa" });
    } else {
      graphService
        .GetProfile()
        .then((profile: GraphProfile) => {
          AppModule.UpdateCompanyName(profile.companyName);

          dataService
            .GetUserProfile(profile)
            .then(() => {
              AppModule.UpdateIsLoading(false);
              router.replace({ name: "Home" });
            })
            .catch(() => {
              AppModule.UpdateIsLoading(false);
              router.replace({ name: "NoProfile" });
            });
        })
        .catch((error: ApplicationError) => {
          console.error(error);
          AppModule.UpdateApplicationError(error);
        });
    }
  },
};

Vue.use(MsalPlugin, options);
Vue.use(GraphServicePlugin);
Vue.use(DataServicePlugin);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

import MsalWrapper from "@/auth/msal-wrapper";
import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { VueConstructor } from "vue";
import { Ticket, TicketNote } from ".";
import { GraphProfile, UserProfile } from "./models";
import { ApplicationError } from "./models/applicationError";
import AppModule from "@/store/modules/app";

declare module "vue/types/vue" {
  interface Vue {
    $dataService: DataService;
  }
  interface VueConstructor {
    $dataService: DataService;
  }
}

export default class DataService {
  private apiBaseUrl = process.env.VUE_APP_APIURL || "/api";

  private async getRequestConfig(
    additionalHeaders?: AxiosRequestHeaders,
    baseConfig: AxiosRequestConfig = {}
  ): Promise<AxiosRequestConfig> {
    const msal = MsalWrapper.getInstance();
    const accessToken = await msal.getToken("api");
    const companyName = AppModule.companyName;

    const requestConfig = Object.assign(baseConfig, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "x-companyname": companyName,
      },
    });

    if (additionalHeaders) {
      requestConfig.headers = Object.assign(requestConfig.headers, additionalHeaders);
    }

    return requestConfig;
  }

  public async GetUserProfile(graphProfile: GraphProfile): Promise<void> {
    return axios
      .post(`${this.apiBaseUrl}/ticket/profile`, graphProfile, await this.getRequestConfig())
      .then((response) => {
        AppModule.UpdateUserProfile(UserProfile.fromApiResponse(response.data));
      })
      .catch((error: Error | AxiosError) => {
        console.error(error);
        throw new ApplicationError(error);
      });
  }

  public async GetAutotaskRedirectUrl(autotaskTicketId: string | null): Promise<string> {
    let querystring = "";
    
    if (autotaskTicketId && !isNaN(parseInt(autotaskTicketId))) {
      querystring = `?autotaskTicketId=${autotaskTicketId}`;
    }

    return axios
      .get(
        `${this.apiBaseUrl}/autotask/getautotaskredirecturl${querystring}`,
        await this.getRequestConfig()
      )
      .then((response) => {
        return response.data;
      })
      .catch((error: Error | AxiosError) => {
        console.error(error);
        throw new ApplicationError(error);
      });
  }

  public async GetAnonymousAutotaskRedirectUrl(emailAddress: string, autotaskTicketId: string | null): Promise<string> {
    const params = new URLSearchParams({emailAddress: emailAddress});
    
    if (autotaskTicketId && !isNaN(parseInt(autotaskTicketId))) {
      params.append("ticketId", autotaskTicketId);
    }
    
    return axios
      .get(`${this.apiBaseUrl}/token/gettoken?${params.toString()}`)
      .then((tokenResponse) => {
        return axios
          .post(`${this.apiBaseUrl}/autotask/getautotaskurlanonymous`, tokenResponse.data)
          .then((urlResponse) => {
            return urlResponse.data;
          })
          .catch((error: Error | AxiosError) => {
            console.error(error);
            throw new ApplicationError(error);
          });
      })
      .catch((error: Error | AxiosError) => {
        console.error(error);
        throw new ApplicationError(error);
      });
  }

  public async GetTickets(state: boolean): Promise<Ticket[]> {
    const route = state ? "" : "closed";
    return axios
      .get(`${this.apiBaseUrl}/ticket/${route}`, await this.getRequestConfig())
      .then((response) => {
        AppModule.UpdateTickets(response.data);
        return response.data;
      })
      .catch((error: Error | AxiosError) => {
        console.error(error);
        throw new ApplicationError(error);
      });
  }

  public async GetTicket(id: number): Promise<Ticket> {
    return axios
      .get(`${this.apiBaseUrl}/ticket/${id}`, await this.getRequestConfig())
      .then((response) => {
        const ticket = response.data;
        AppModule.UpdateSelectedTicket(ticket);
        if (ticket.solution) {
          AppModule.AppendSelectedTicketNote(TicketNote.newNoteSolution(ticket));
        }
        if (ticket.notes.length == 0 && ticket.isClosed) {
          AppModule.AppendSelectedTicketNote(
            TicketNote.newNoteBody("-- Geen notities aanwezig --")
          );
        }
        return ticket;
      })
      .catch((error: Error | AxiosError) => {
        console.error(error);
        throw new ApplicationError(error);
      });
  }

  public async CreateTicket(ticket: Ticket): Promise<Ticket> {
    const ticketJson = JSON.stringify(ticket);
    return axios
      .post(
        `${this.apiBaseUrl}/ticket`,
        ticketJson,
        await this.getRequestConfig({ "Content-Type": "application/json" })
      )
      .then((response) => {
        return response.data;
      })
      .catch((error: Error | AxiosError) => {
        console.error(error);
        throw new ApplicationError(error);
      });
  }

  public async CreateTicketNote(note: TicketNote): Promise<TicketNote> {
    const noteJson = JSON.stringify(note);
    return axios
      .post(
        `${this.apiBaseUrl}/ticket/${note.ticketId}/note`,
        noteJson,
        await this.getRequestConfig({ "Content-Type": "application/json" })
      )
      .then((response) => {
        return response.data;
      })
      .catch((error: Error | AxiosError) => {
        console.error(error);
        throw new ApplicationError(error);
      });
  }

  public async UploadFiles(ticketId: number, formData: FormData): Promise<string[]> {
    return axios
      .post(
        `${this.apiBaseUrl}/ticket/${ticketId}/upload`,
        formData,
        await this.getRequestConfig({ "Content-Type": "multipart/form-data" })
      )
      .then((response) => {
        const uploadedFiles: string[] = response.data;
        return uploadedFiles;
      })
      .catch((error: Error | AxiosError) => {
        console.error(error);
        throw new ApplicationError(error);
      });
  }

  public async DownloadFile(
    ticketId: number,
    fileId: number,
    fileName: string,
    returnUrl = false
  ): Promise<string> {
    return axios
      .get(
        `${this.apiBaseUrl}/ticket/${ticketId}/download/${fileId}`,
        await this.getRequestConfig(
          { "Content-Type": "application/octet-stream" },
          { responseType: "blob" }
        )
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        if (returnUrl) {
          return url;
        }

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        return "";
      })
      .catch((error: Error | AxiosError) => {
        console.error(error);
        throw new ApplicationError(error);
      });
  }
}

export const dataService = new DataService();

export const DataServicePlugin = {
  install(Vue: VueConstructor): void {
    Vue.prototype.$dataService = dataService;
  },
};
